<script lang="ts">
  import { onDestroy } from 'svelte'
  import { atom, computed } from 'nanostores'

  import { Search } from 'carbon-components-svelte'

  import { state } from '../../../store/state'
  import { router } from '../../../routing/router'
  import { isThisKind } from '../../../routing/router-utils'

  import NothingToDisplay from '../../atoms/nothing-to-display.svelte'

  import SavedSearchItem from './saved-search-details.svelte'

  const { savedSearches } = state.userData
  const { searches } = savedSearches

  const text = atom('')
  const KEY = 'savedSearchFilter'

  const visibleSearches = computed([searches, text], (searches, text) => {
    return searches.filter((search) => search.name.toLowerCase().includes(text.toLowerCase()))
  })

  let updating = false
  let lastText = ''

  const doFilter = (value: string) => {
    if (value === lastText) {
      return
    }

    lastText = value
    if (value) {
      router(state).upsertQueryParams({ savedSearchFilter: value }).andStay()
    } else {
      router(state).deleteOneParam(KEY).andStay()
    }
  }

  const textUnsubscribe = text.subscribe(doFilter)

  const hashUnsubscribe = state.currentHash.subscribe(() => {
    if (!isThisKind('saved-search', state)) {
      return
    }

    if (updating) {
      return
    }

    try {
      updating = true
      const hasParam = router(state).route().hasParam(KEY)

      if (!hasParam) {
        return
      }

      const value = router(state).route().params[KEY]

      text.set(value)
    } finally {
      updating = false
    }
  })

  onDestroy(() => {
    textUnsubscribe && textUnsubscribe()
    hashUnsubscribe && hashUnsubscribe()
  })

  let edited = '(filtered)'
</script>

<div class="saved-searches flex flex-col w-full h-full gap-4 px-10 pt-10 pb-4">
  <div class="flex flex-row gap-4">
    <h1 class="text-3xl font-bold">Saved searches</h1>
    <div class="text-[var(--dark-gray)] self-end" class:hidden={!$text.length}>{edited}</div>
  </div>
  <div class="max-w-[60%]">
    <Search bind:value={$text} />
  </div>
  <div class="flex w-full h-full gap-4 overflow-x-auto pb-4">
    {#if $visibleSearches.length === 0 && $searches.length > 0}
      <NothingToDisplay text="No saved searches match filter" />
    {/if}
    {#if $searches.length === 0}
      <NothingToDisplay text="You don't have any saved search" />
    {/if}
    {#each $visibleSearches as data, index}
      <SavedSearchItem {data} index={index + 1} />
    {/each}
  </div>
</div>

<style lang="postcss">
</style>
