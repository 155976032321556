<script lang="ts">
  import { type WritableStore } from 'nanostores'
  import { OverflowMenu, OverflowMenuItem, Tooltip } from 'carbon-components-svelte'
  import Launch from 'carbon-icons-svelte/lib/Launch.svelte'
  import TrashCan from 'carbon-icons-svelte/lib/TrashCan.svelte'
  import PageFirst from 'carbon-icons-svelte/lib/PageFirst.svelte'
  import StringText from 'carbon-icons-svelte/lib/StringText.svelte'
  import Home from 'carbon-icons-svelte/lib/Home.svelte'

  import { type SavedSearch } from 'common/src/types'
  import { state } from '../../../store/state'
  import MyIcon from '../../atoms/my-icon.svelte'
  import { modal } from '../../../store/modal'

  export let index: number
  export let data: SavedSearch

  const worker = state.userData.savedSearches

  const handleRemove = () => {
    modal(state)
      .danger()
      .heading('Delete saved search')
      .text(`Are you sure you want to delete saved search "${data.name}"?`)
      .primaryText('Delete')
      .secondaryText('Cancel')
      .open(async () => {
        worker.removeSearch(data.id)
      })
  }

  const executeAndUnsubscribe = (
    store: WritableStore<() => Promise<any>>,
    unsubscribe: () => void | undefined
  ) => {
    const action = store.get()
    store.set(async () => {
      await action()
      unsubscribe && unsubscribe()
    })
  }

  let renameUnsubscribe: () => void
  const rename = () => {
    const modal = worker.modal.open(data)

    const originalName = data.name

    modal.heading.set('Rename saved search')
    modal.primaryText.set('Rename')

    const modalModel = worker.modal
    renameUnsubscribe = modalModel.searchName.subscribe((name) => {
      const isDisabled = !name || name === originalName
      modal.primaryButtonDisabled.set(isDisabled)
    })

    executeAndUnsubscribe(modal.secondaryAction, renameUnsubscribe)
    executeAndUnsubscribe(modal.primaryAction, renameUnsubscribe)
  }

  const onMenuClose = (detail: any) => {
    if (!detail) return

    switch (detail.index) {
      case 0:
        return worker.navigateToSearch(data)
      case 1:
        return rename()
      case 2:
        return worker.toFirstPosition(data.id)
      case 3:
        return worker.viewInHome(data.id)
      case 4:
        return handleRemove()
      default:
        return
    }
  }

  let tooltipOpen = false
  let tooltipTimeout: any
</script>

<div
  class="saved-search-details flex flex-col w-1/5 bg-background gap-4 p-2 rounded-custom border border-solid border-selected shadow"
>
  <div class="flex flex-row">
    <div class="hidden pr-2">{index}</div>
    <p class="font-bold flex-grow">{data.name}</p>

    <!-- svelte-ignore a11y-no-static-element-interactions -->
    <div
      class="p-1 pt-2"
      class:hidden={!data.isInHome}
      on:mouseenter={() => {
        tooltipTimeout = setTimeout(() => {
          tooltipOpen = true
        }, 500)
      }}
      on:mouseleave={() => {
        tooltipOpen = false
        clearTimeout(tooltipTimeout)
      }}
    >
      <Tooltip open={tooltipOpen} icon={Home}>Displayed on the home screen</Tooltip>
    </div>
    <OverflowMenu
      flipped
      size="sm"
      on:keydown={(ev) => {
        ev.stopPropagation()
      }}
      on:close={(ev) => onMenuClose({ ...ev?.detail })}
    >
      <OverflowMenuItem>
        <div class="flex flex-row gap-2">
          <div>edit</div>
          <div><MyIcon icon={Launch} /></div>
        </div>
      </OverflowMenuItem>
      <OverflowMenuItem>
        <div class="flex flex-row gap-2">
          <div>rename</div>
          <div><MyIcon icon={StringText} /></div>
        </div>
      </OverflowMenuItem>
      <OverflowMenuItem>
        <div class="flex flex-row gap-2">
          <div>to first position</div>
          <div><MyIcon icon={PageFirst} /></div>
        </div>
      </OverflowMenuItem>
      <OverflowMenuItem>
        <div class="flex flex-row gap-2">
          <div>pin to home</div>
          <div><MyIcon icon={Home} /></div>
        </div>
      </OverflowMenuItem>
      <OverflowMenuItem>
        <div class="flex flex-row gap-2">
          <div>delete</div>
          <div><MyIcon icon={TrashCan} type="danger" /></div>
        </div>
      </OverflowMenuItem>
    </OverflowMenu>
  </div>
  <div class="flex flex-col">
    <div style="word-wrap: break-word; color: var(--background);">
      {JSON.stringify(data)}
    </div>
  </div>
</div>

<style>
  .saved-search-details :global(.bx--overflow-menu > .bx--overflow-menu-options > li > button) {
    justify-content: flex-end;
  }
</style>
